import BackTop from '@scripts/components/BackTop'
import Carousel from '@scripts/components/Carousel'
import DomainNavigation from '@scripts/components/DomainNavigation'
import Form from '@scripts/components/Form'
import HeroCarousel from '@scripts/components/HeroCarousel'
import ImageLazyLoad from '@scripts/components/ImageLazyLoad'
import PageHeader from '@scripts/components/PageHeader'
import Team from '@scripts/components/Team'
import MainNavigation from '@scripts/structures/MainNavigation'

class Application {
    private namespace: string = 'application'

    private navigationInstance?: MainNavigation
    private backTopInstance?: BackTop
    private imageLazyLoadInstance?: ImageLazyLoad
    private carouselInstance?: Carousel
    private heroCarouselInstance?: HeroCarousel
    private teamInstance?: Team
    private formInstance?: Form
    private pageHeader?: PageHeader
    private domainNavigation?: DomainNavigation

    constructor() {
        console.log('[%s] bootstrap', this.namespace)
        this.navigationInstance = new MainNavigation()
        this.backTopInstance = new BackTop()
        this.imageLazyLoadInstance = new ImageLazyLoad()
        this.carouselInstance = new Carousel()
        this.heroCarouselInstance = new HeroCarousel()
        this.teamInstance = new Team()
        this.formInstance = new Form()
        this.pageHeader = new PageHeader()
        this.domainNavigation = new DomainNavigation()
    }

    public start(): void {
        console.log('[%s] started', this.namespace)
        this.navigationInstance?.start()
        this.backTopInstance?.start()
        this.imageLazyLoadInstance?.start()
        this.carouselInstance?.start()
        this.heroCarouselInstance?.start()
        this.teamInstance?.start()
        this.formInstance?.start()
        this.pageHeader?.start()
        this.domainNavigation?.start()
    }
}

const ApplicationInstance: Application = new Application()

export default ApplicationInstance
