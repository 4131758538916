class DomainNavigation {
    protected namespace: string = 'domain-navigation'
    protected element?: HTMLElement | null

    constructor() {
        console.info('[%s] bootstrap', this.namespace)
        this.element = document.querySelector('.domain-navigation--items')
    }

    public start(): void {
        console.info('[%s] start', this.namespace)

        const random = Math.random()
        const orderClassname = random < 0.5 ? 'reverse' : 'normal'
        this.element?.classList.add(orderClassname)
    }
}

export default DomainNavigation
